var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../config-global';
//
import Header from './Header';
// ----------------------------------------------------------------------
export default function CompactLayout() {
    var isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { isOffset: isOffset }), _jsx(Container, __assign({ component: "main" }, { children: _jsx(Stack, __assign({ sx: {
                        py: 12,
                        m: 'auto',
                        maxWidth: 400,
                        minHeight: '100vh',
                        textAlign: 'center',
                        justifyContent: 'center',
                    } }, { children: _jsx(Outlet, {}) })) }))] }));
}
